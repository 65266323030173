/**
 * The TeaserBar component
 */
import React from "react";
import PropTypes from "prop-types";
import {
  FaPaypal,
  FaCcVisa,
  FaCcMastercard,
  FaAmazonPay,
  FaCcAmex,
  FaMoneyCheck,
} from "react-icons/fa";
import { Badge } from "reactstrap";

/**
 * TeaserBar stateless component
 * - Notifies user that the page is offline
 * - Turn app offline to see the component
 */
const PaymentMethodBadges = (props, context) => {
  return (
    <span className="h3">
      <Badge
        color="light"
        className="mr-1 px-2 py-0"
        style={{
          backgroundColor: "#dee2e6",
          height: "1.75rem",
        }}
      >
        <abbr title="PayPal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="28"
            height="28"
            viewBox="0 0 48 48"
          >
            <path
              fill="#1565C0"
              d="M18.7,13.767l0.005,0.002C18.809,13.326,19.187,13,19.66,13h13.472c0.017,0,0.034-0.007,0.051-0.006C32.896,8.215,28.887,6,25.35,6H11.878c-0.474,0-0.852,0.335-0.955,0.777l-0.005-0.002L5.029,33.813l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,0.991,1,0.991h8.071L18.7,13.767z"
            ></path>
            <path
              fill="#039BE5"
              d="M33.183,12.994c0.053,0.876-0.005,1.829-0.229,2.882c-1.281,5.995-5.912,9.115-11.635,9.115c0,0-3.47,0-4.313,0c-0.521,0-0.767,0.306-0.88,0.54l-1.74,8.049l-0.305,1.429h-0.006l-1.263,5.796l0.013,0.001c-0.014,0.064-0.039,0.125-0.039,0.194c0,0.553,0.447,1,1,1h7.333l0.013-0.01c0.472-0.007,0.847-0.344,0.945-0.788l0.018-0.015l1.812-8.416c0,0,0.126-0.803,0.97-0.803s4.178,0,4.178,0c5.723,0,10.401-3.106,11.683-9.102C42.18,16.106,37.358,13.019,33.183,12.994z"
            ></path>
            <path
              fill="#283593"
              d="M19.66,13c-0.474,0-0.852,0.326-0.955,0.769L18.7,13.767l-2.575,11.765c0.113-0.234,0.359-0.54,0.88-0.54c0.844,0,4.235,0,4.235,0c5.723,0,10.432-3.12,11.713-9.115c0.225-1.053,0.282-2.006,0.229-2.882C33.166,12.993,33.148,13,33.132,13H19.66z"
            ></path>
          </svg>
        </abbr>
      </Badge>
      <Badge
        color="light"
        className="mr-1 px-2 py-0"
        style={{
          backgroundColor: "#dee2e6",
          height: "1.75rem",
        }}
      >
        <abbr title="Visa">
          <svg
            fill="#1434cb"
            width="28"
            height="28"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16.539 9.186a4.155 4.155 0 0 0-1.451-.251c-1.6 0-2.73.806-2.738 1.963-.01.85.803 1.329 1.418 1.613.631.292.842.476.84.737-.004.397-.504.577-.969.577-.639 0-.988-.089-1.525-.312l-.199-.093-.227 1.332c.389.162 1.09.301 1.814.313 1.701 0 2.813-.801 2.826-2.032.014-.679-.426-1.192-1.352-1.616-.563-.275-.912-.459-.912-.738 0-.247.299-.511.924-.511a2.95 2.95 0 0 1 1.213.229l.15.067.227-1.287-.039.009zm4.152-.143h-1.25c-.389 0-.682.107-.852.493l-2.404 5.446h1.701l.34-.893 2.076.002c.049.209.199.891.199.891h1.5l-1.31-5.939zm-10.642-.05h1.621l-1.014 5.942H9.037l1.012-5.944v.002zm-4.115 3.275.168.825 1.584-4.05h1.717l-2.551 5.931H5.139l-1.4-5.022a.339.339 0 0 0-.149-.199 6.948 6.948 0 0 0-1.592-.589l.022-.125h2.609c.354.014.639.125.734.503l.57 2.729v-.003zm12.757.606.646-1.662c-.008.018.133-.343.215-.566l.111.513.375 1.714H18.69v.001h.001z" />
          </svg>
        </abbr>
      </Badge>
      <Badge
        color="light"
        className="mr-1 px-2 py-0"
        style={{
          backgroundColor: "#dee2e6",
          height: "1.75rem",
        }}
      >
        <abbr title="MasterCard">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="28"
            height="28"
            viewBox="0 0 48 48"
          >
            <path
              fill="#ff9800"
              d="M32 10A14 14 0 1 0 32 38A14 14 0 1 0 32 10Z"
            ></path>
            <path
              fill="#d50000"
              d="M16 10A14 14 0 1 0 16 38A14 14 0 1 0 16 10Z"
            ></path>
            <path
              fill="#ff3d00"
              d="M18,24c0,4.755,2.376,8.95,6,11.48c3.624-2.53,6-6.725,6-11.48s-2.376-8.95-6-11.48 C20.376,15.05,18,19.245,18,24z"
            ></path>
          </svg>
        </abbr>
      </Badge>
      <Badge
        color="light"
        className="mr-1 px-2 py-0"
        style={{
          backgroundColor: "#dee2e6",
          color: "#006fcf",
          height: "1.75rem",
          fontSize: "1.5rem",
        }}
      >
        <abbr title="American Express">
          <FaCcAmex />
        </abbr>
      </Badge>
      <Badge
        color="light"
        className="mr-1 px-2 py-0"
        style={{
          backgroundColor: "#dee2e6",
          height: "1.75rem",
          fontSize: "1.5rem",
        }}
      >
        <abbr title="AmazonPay">
          <FaAmazonPay />
        </abbr>
      </Badge>
      <Badge
        color="light"
        className="mr-5 px-2 py-0"
        style={{
          backgroundColor: "#dee2e6",
          height: "1.75rem",

          fontSize: "1.5rem",
        }}
      >
        <abbr title="Banküberweisung">
          <FaMoneyCheck />
        </abbr>
      </Badge>
    </span>
  );
};

/** define proptypes for the form */
PaymentMethodBadges.propTypes = {};

/**  define proptype for the 'translation' function  */
PaymentMethodBadges.contextTypes = {
  t: PropTypes.func,
};
export default PaymentMethodBadges;
